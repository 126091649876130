import githubIcon from '../img/github.png';
import linkedinIcon from '../img/linkedin-plain.png';
import slackIcon from '../img/slack-plain.png';
import twitterIcon from '../img/twitter-original.png';

export {
  githubIcon,
  linkedinIcon,
  slackIcon,
  twitterIcon,
};